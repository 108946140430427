.dialog-footer[data-v-350edffc] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 12px 0;
  border-top: 1px solid rgb(204, 204, 204);
}
.iconLine[data-v-350edffc] {
  font-size: 24px;
  margin-left: 6px;
  padding: 4px 0;
}
[data-v-350edffc] .el-form-item__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.borderLine[data-v-350edffc] {
  width: 100%;
  border-top: 1px solid #DCDFE6;
  padding-top: 12px;
}
.formBox[data-v-350edffc] {
  height: calc(100vh - 251px);
  overflow: auto;
  overflow-x: hidden;
}